import React, { useEffect } from 'react'
import DefaultTable from './DefaultTable'

function DetailModal({selectedItem,onClose}) {

    useEffect(()=>{
        console.log("selected item",selectedItem)
    },[])

    const handleClose = ()=>{
        onClose()
    }

  return (
    <div className='w-[60rem] h-[30rem]'>
        <div class="w-full h-[15%] px-6 flex items-center justify-between border-b  border-[#E2E8F0]">
        <div>
        <span class="text-[18px] font-sans font-[600]">Recently sold product(s) on eBay</span>
        </div>

    
        <div
          onClick={handleClose}  
          class="cursor-pointer p-[8px] rounded-md hover:bg-[#F0F3FA]"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="18"
              height="18"
            >
              <path
                stroke="currentColor"
                stroke-width="1.2"
                d="m1.5 1.5 15 15m0-15-15 15"
              ></path>
            </svg>
          </span>
        </div>
        </div>

        <div className='my-6 px-4 space-y-6 w-full h-[70%] overflow-y-auto'>
         <DefaultTable tableData={selectedItem} />
        </div>
    </div>
  )
}

export default DetailModal