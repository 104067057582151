import React, { useState, useEffect } from "react";
import BaseModal from "./BaseModal";
import DetailModal from "./DetailModal";

function DefaultTable({ tableData }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setOpenModal(true);
    }
  }, [selectedItem]);

  const handleCloseModal = () => {
    setSelectedItem(null);
    setOpenModal(false);
  };

  return (
    <div className="w-full h-full">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                TITLE
              </th>
              <th scope="col" className="px-6 py-3">
                PRICE
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item, i) => (
              <tr
                key={i}
                onClick={() => setSelectedItem(item?.item)}
                className={`bg-white border-b hover:bg-gray-100 ${
                  i % 2 !== 0 ? "bg-[#F9FAFB]" : "bg-white"
                }`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  <div class="flex items-center w-fit">
                    {/* <div className="w-16 h-12">
                                    <img src={item?.image} className="w-full h-full object-contain" />
                                </div> */}
                    <div className="hover:underline cursor-pointer">
                      <a href={item?.link} target="_blank">
                        {item?.title}
                      </a>
                    </div>
                  </div>
                </th>

                <td className="px-6 py-4">${item?.prices?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DefaultTable;
