import { useState, useEffect } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import ShopingCard from "./components/ShopingCard";
import axios from "axios";
import Model from "./components/Model";
import SelectionArea from "./components/SelectionArea";
import Table from "./components/Table";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const screenWidth = window.innerWidth;
  const [search, setSearch] = useState("");
  const [company, setCompany] = useState("");
  const [storage, setStorage] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [goToFirst, setGoToFirst] = useState(false);
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const companies = [
    {
      name: "Apple",
      img: "https://cdn-icons-png.flaticon.com/512/152/152752.png",
      model: [
        {
          id: 2,
          model: "iPhone 13",
          modelName: "iPhone 13",
          img: "https://static.toiimg.com/thumb/msid-107352637,width-1280,height-720,resizemode-4/107352637.jpg",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
        {
          id: 3,
          model: "iPhone 12",
          modelName: "iPhone 12",
          img: "https://static.toiimg.com/thumb/msid-107352637,width-1280,height-720,resizemode-4/107352637.jpg",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
        {
          id: 4,
          model: "iPhone 11",
          modelName: "iPhone 11",
          img: "https://static.toiimg.com/thumb/msid-107352637,width-1280,height-720,resizemode-4/107352637.jpg",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
      ],
    },
    {
      name: "Samsung",
      img: "https://1000logos.net/wp-content/uploads/2017/06/Font-Samsung-Logo.jpg",
      model: [
        {
          id: 2,
          model: "Galaxy A11",
          modelName: "Galaxy A11",
          img: "https://images.priceoye.pk/samsung-galaxy-a13-pakistan-priceoye-f5zsh.jpg",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
        {
          id: 3,
          model: "Galaxy A24",
          modelName: "Galaxy A24",
          img: "https://images.priceoye.pk/samsung-galaxy-a13-pakistan-priceoye-f5zsh.jpg",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
        {
          id: 4,
          model: "Galaxy A52",
          modelName: "Galaxy A52",
          img: "https://images.priceoye.pk/samsung-galaxy-a13-pakistan-priceoye-f5zsh.jpg",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
      ],
    },
    {
      name: "Oppo",
      img: "https://i.pinimg.com/originals/9f/c2/60/9fc2604b5e46b15575f807ffacf7c95c.png",
      model: [
        {
          id: 2,
          model: "Oppo F15",
          modelName: "F15",
          img: "https://image.oppo.com/content/dam/oppo/common/mkt/v2-2/navigation/reno-series/reno5/reno5-navigation-silver-v2.png.thumb.webp",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
        {
          id: 3,
          model: "Oppo A5",
          modelName: "A5",
          img: "https://image.oppo.com/content/dam/oppo/common/mkt/v2-2/navigation/reno-series/reno5/reno5-navigation-silver-v2.png.thumb.webp",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
        {
          id: 4,
          model: "Oppo A60",
          modelName: "A60",
          img: "https://image.oppo.com/content/dam/oppo/common/mkt/v2-2/navigation/reno-series/reno5/reno5-navigation-silver-v2.png.thumb.webp",
          storage: [{ size: "128" }, { size: "256" }, { size: "512" }],
        },
      ],
    },
  ];
  const [model, setModel] = useState("");
  const [name, setName] = useState("");
  const [avg, setavg] = useState("");
  const [errMsg, setErrMsg] = useState("please search data");
  useEffect(() => {
    console.log(name);
  }, [name]);

  const searchText = (text) => {
    console.log(text.target.value);
    setSearch(text.target.value);
  };

  const handleGoToFirst = () => {
    setGoToFirst(true);
  };

  const getComapny = (text) => {
    setCompany(text.target.value);
  };

  const getstorage = (text) => {
    setStorage(text.target.value);
  };
  const getmodel = (text) => {
    setModel(text.target.value);
  };

  // http://192.168.0.108:8080/
  const searching = async (item) => {
    console.log("items", item);
    // console.log(item);
    setIsLoading(true);
    try {
      const res = await axios.get(
        `https://buybackpos-core.dimensionalsys.com/api/ebay/recent-sold?storage=${item.storage}GB&brandName=${item.company}&modelName=${item.model}`
      );
      if (res?.data?.data?.length > 0) {
        setTableData(res?.data?.data);
      } else {
        setTableData([]);
      }
      setIsLoading(false);
      // setavg(res?.data?.averagePrice);
      // setTableData(res.data?.topItems);
    } catch (error) {
      setTableData([]);
      setError("No Data Found");
    }
    setIsLoading(false);
  };
  console.log(typeof avg, "ressss");
  const numbers = (80 * avg) / 100;

  const handleSave = (item) => {
    console.log("save==>", item);
    searching(item);
    setShowTable(true);
  };

  return (
    <>
      <div className="w-full h-full overflow-x-hidden">
        <div className="h-full w-full">
          <NavBar
            searchText={searchText}
            action={searching}
            search={search}
            handleGoToFirst={handleGoToFirst}
          />
        </div>
        <div className="w-full pb-[3rem] overflow-y-auto no-scrollbar flex mt-[4rem] flex-col justify-center items-center">
          <div className={`h-full w-8/12`}>
            <SelectionArea
              companies={companies}
              handleSave={handleSave}
              goToFirst={goToFirst}
            />
          </div>

          <div className={`h-full w-8/12 mt-[4rem]`}>
            {isLoading ? (
              <div className="flex items-center justify-center mt-[4rem]">
                <svg
                  aria-hidden="true"
                  class="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#1F2937]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            ) : tableData?.length > 0 ? (
              <Table tableData={tableData} />
            ) : error ? (
              <div className="flex items-center justify-center mt-[4rem] !text-[2rem]">
                {error}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>

      {/* <Model
        storage={storage}
        company={company}
        model={model}
        setStorage={getstorage}
        setCompany={getComapny}
        setModel={getmodel}
        searching={searching}
        setName={setName}
      /> */}

      {/* <>
        {data?.length > 0 && (
          <div className="flex items-center justify-between flex-col  px-5 py-10 w-full">
            <div className="py-2">
              <label>Avarge Price</label>
              <span> : ${avg.toFixed(2)} </span>
            </div>

            <div>
              <label>80% of Avarge Price</label>
              <span> :${numbers.toFixed(2)}</span>
            </div>
          </div>
        )}
      </> */}

      {/* <div className="  justify-between px-5 py-10">
        {data?.length < 1 ? (
          <div className="flex justify-center h-[80vh] w-full items-center">
            <h1>{errMsg} </h1>
          </div>
        ) : (
          <>
            <tr>
              <th>Name</th>
              <th>Prices</th>
              <th>Date & Time</th>
              <th>Links</th>
            </tr>
            {data?.map((data) => (
              <ShopingCard data={data} />
            ))}
          </>
        )}
      </div> */}
    </>
  );
}

export default App;
