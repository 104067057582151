import React, { useEffect, useState } from "react";

function SelectionArea({ companies, handleSave, goToFirst }) {
  const [step, setStep] = useState(1);
  const steps = [1, 2, 3];
  const [company, setCompany] = useState("");
  const [model, setModel] = useState("");
  const [storage, setStorage] = useState();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [heading, setHeading] = useState("Select a Brand");

  useEffect(() => {
    if (step == 1) {
      setHeading("Select a Brand");
    } else if (step == 2) {
      setHeading("Select a Model");
    } else if (step == 3) {
      setHeading("Select a Storage");
    }
  }, [step]);

  const handleStep1 = (item) => {
    setCompany(item.name);
    setSelectedCompany(item);
  };
  const handleStep2 = (item) => {
    console.log("item", item);

    setSelectedModel(item);
    setModel(item.modelName);
  };

  const handlePrev = () => {
    setStep((prev) => prev - 1);
  };
  const handleNext = () => {
    if (step == 1) {
      if (company) {
        setStep((prev) => prev + 1);
      } else {
        alert("Select company");
      }
    } else if (step == 2) {
      if (model) {
        setStep((prev) => prev + 1);
      } else {
        alert("Select model");
      }
    }
  };
  const onSave = () => {
    if (storage) {
      handleSave({ company, model, storage });
    } else {
      alert("Select storage");
    }
  };

  return (
    <div classNameName="w-full h-full">
      <div className="w-full items-center justify-center mb-[4rem]">
        <section className="flex items-center justify-center">
          <div className="w-full relative flex items-center justify-between">
            <div className="w-[56%] h-[0.3rem] bg-[#E5E7EB] absolute" />
            {steps.map((item, i) => (
              <div className="w-full cursor-default" key={i}>
                <div
                  className={`-flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center  text-white relative z-10 title-font font-medium text-sm ${
                    step >= item ? "bg-[#1F2937]" : "bg-[#7f7f7f]"
                  }`}
                >
                  {item}
                </div>
              </div>
            ))}
            <div className="flex items-center space-x-4 justify-center">
              {
                <div
                  onClick={handlePrev}
                  className={`rounded-[4px] text-[#FFFFFF] hover:bg-[#2b3644] cursor-pointer bg-[#1F2937] px-8 py-1 ${
                    step > 1 ? "" : "pointer-events-none opacity-50"
                  }`}
                >
                  Previous
                </div>
              }
              {step != 3 && (
                <div
                  onClick={handleNext}
                  className="rounded-[4px] text-[#FFFFFF] hover:bg-[#2b3644] cursor-pointer bg-[#1F2937] px-8 py-1"
                >
                  Next
                </div>
              )}
              {step == 3 && (
                <div
                  onClick={onSave}
                  className="rounded-[4px] text-[#FFFFFF] hover:bg-[#2b3644] cursor-pointer bg-[#1F2937] px-8 py-1"
                >
                  Search
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      {step == 3 && storage && (
        <div className="my-4">
          <div className="text-[1.3rem] font-semibold mb-2">
            Selected Product
          </div>
          <div>
            Brand: <span className="font-semibold">{company || "N/A"}</span>
          </div>
          <div>
            Model: <span className="font-semibold">{model || "N/A"}</span>
          </div>
          <div>
            Storage:{" "}
            <span className="font-semibold">{`${storage} GB` || "N/A"}</span>
          </div>
        </div>
      )}
      <div className="text-[2rem] mb-[1rem] font-semibold">{heading}</div>
      {step == 1 ? (
        <div className="w-full h-auto flex items-center flex-wrap gap-6">
          {companies?.map((item, i) => (
            <div
              onClick={() => handleStep1(item)}
              className={`w-[30%] border h-[15rem] cursor-pointer hover:border-[#1F293750] rounded-[6px] shadow-lg flex flex-col items-center justify-center gap-y-4 ${
                item.name == company ? "border-[#1F2937]" : ""
              }`}
              key={i}
            >
              <div className="w-[15rem] h-[8rem]">
                <img src={item.img} className="w-full h-full object-contain" />
              </div>
              <div className="text-[1.2rem] font-semibold">{item.name}</div>
            </div>
          ))}
        </div>
      ) : step == 2 ? (
        <div className="w-full h-auto flex items-center flex-wrap gap-6">
          {selectedCompany?.model?.map((item, i) => (
            <div
              onClick={() => handleStep2(item)}
              className={`w-[30%] border h-[15rem] cursor-pointer hover:border-[#1F293750] rounded-[6px] shadow-lg flex flex-col items-center justify-center gap-y-4 ${
                item.modelName == model ? "border-[#1F2937]" : ""
              }`}
              key={i}
            >
              <div className="w-[15rem] h-[8rem]">
                <img src={item.img} className="w-full h-full object-contain" />
              </div>
              <div className="text-[1.2rem] font-semibold">{item.model}</div>
            </div>
          ))}
        </div>
      ) : step == 3 ? (
        <div className="w-full h-auto flex items-center flex-wrap gap-6">
          {selectedModel?.storage?.map((item, i) => (
            <div
              onClick={() => setStorage(item.size)}
              className={`w-[30%] border h-[15rem] cursor-pointer hover:border-[#1F293750] rounded-[6px] shadow-lg flex flex-col items-center justify-center gap-y-4 ${
                item.size == storage ? "border-[#1F2937]" : ""
              }`}
              key={i}
            >
              <div className="text-[3rem] font-semibold">{item.size} GB</div>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default SelectionArea;
