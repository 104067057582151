import React from "react";

const NavBar = ({ handleGoToFirst }) => {
  return (
    <nav className="flex bg-[#1F2937] fixed sticky top-0 items-center justify-center py-[2rem] border-b">
      <div onClick={handleGoToFirst} className="text-[#FFFFFF] cursor-pointer font-bold text-3xl w-8/12">
        REUSELY REPLICA
      </div>
    </nav>
  );
};

export default NavBar;
