import React from 'react'

function BaseModal({children}) {
  return (
    <div className="base-modal overflow-hidden">
    <div
      className="fixed inset-0 backdrop-blur-sm bg-[#00000050] overflow-hidden transition-opacity base-modal"
    ></div>

    <div className="fixed base-modal inset-0 overflow-hidden">
      <div
        className="flex items-center justify-center min-h-full p-4 text-center sm:p-0"
      >
        <div
        
          className="bg-[#FFFFFF] relative dark:border-[#32323280] dark:shadow dark:border shadow-xl -p-2 overflow-x-auto text-left overflow-hidden transform transition-all sm:my-8 rounded-[8px]"
        >
         {children}
        </div>
      </div>
    </div>
  </div>
  )
}

export default BaseModal