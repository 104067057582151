import React, { useState, useEffect } from "react";
import BaseModal from "./BaseModal";
import DetailModal from "./DetailModal";

function Table({ tableData }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setOpenModal(true);
    }
  }, [selectedItem]);

  const handleCloseModal = () => {
    setSelectedItem(null);
    setOpenModal(false);
  };

  return (
    <div className="w-full h-full">
      {openModal && (
        <BaseModal>
          <DetailModal selectedItem={selectedItem} onClose={handleCloseModal} />
        </BaseModal>
      )}

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Search Results
          </caption>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Title
              </th>
              <th scope="col" className="px-6 py-3">
                Average Price
              </th>
              <th scope="col" className="px-6 py-3">
                80%
              </th>
              <th scope="col" className="px-6 py-3">
                Condition
              </th>

              <th scope="col" className="px-6 py-3">
                Total Product
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item, i) => (
              <tr
                key={i}
                onClick={() => setSelectedItem(item?.item)}
                className={`bg-white border-b hover:bg-gray-100 ${
                  i % 2 !== 0 ? "bg-[#F9FAFB]" : "bg-white"
                }`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  <div class="flex items-center w-fit">
                    <div className="w-16 h-12">
                      <img
                        src={item?.image}
                        className="w-full h-full object-contain "
                      />
                    </div>
                    <div className="hover:underline cursor-pointer capitalize pl-[5px]">
                      {item?.title}
                    </div>
                  </div>
                </th>
                <td className="px-6 py-4">
                  ${item?.avg ? item?.avg?.toFixed(2) : "N/A"}
                </td>
                <td className="px-6 py-4">
                  ${item?.eightyPer ? item?.eightyPer.toFixed(2) : "N/A"}
                </td>
                <td className="px-6 py-4">{item?.condition}</td>

                <td className="px-6 py-4">{item?.item?.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
